import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useCookies } from 'react-cookie';
const gaId = window.siteVar.referer === 'UK' ? 'G-SKPFMZ4RJB' : 'G-CT46K5CJXJ'
ReactGA.initialize(gaId);
/* global fbq */
function Intro() {
  const [response, setResponse] = useState("unassigned");
  let link = "https://www.trailfinders.com/privacy";
  if (window.siteVar.referer === "IE") {
    link = "https://www.trailfinders.ie/privacy";
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      title: "",
      GivenName: "",
      FamilyName: "",
      Email: "",
      EmailConfirm: "",
    },
  });
  
const [cookies] = useCookies();
//console.log('reactcookies = ',cookies.smid)
  return (
    <div className="d-flex align-items-center container-fluid justify-content-center beach_bg h-100">
      <div className="my-auto w-100 h-100 d-flex flex-column justify-content-center">
        <img
          src="https://cdn.trailfinders.com/tf-subscribe.png?width=125"
          alt="subscribe"
          className="mt-3 mx-auto"
        ></img>

        <h1 className="fs-3 text-warning text-center">TF's Bulletin</h1>
        <h2 className="fs-4 text-center text-light">
          Sign up for TF's Special Offers & Travel Articles
        </h2>

        <form
          className="ModalForm w-100"
          onSubmit={handleSubmit((data) => {
            window.sessionStorage.setItem("hasSubscribed", "hasSubscribed");
            const postData = {};
            postData.mongoData = {};
            postData.mongoData.email = data.Email;
            postData.mongoData.title = "";
            postData.mongoData.name = "";
            postData.mongoData.surname = "";
            postData.mongoData.source = "web";
            postData.mongoData.partition = window.siteVar.referer;
            postData.mongoData.SMID = "";
            if (cookies.smid) {
              postData.mongoData.SMID = cookies.smid;
      
           } 
            postData.send_to = data.Email;
            postData.version = "1";
            postData.ieBooking = false;
            postData.email = data.Email;
            postData.title = "";
            postData.firstname = "";
            postData.surname = "";
            postData.partition = window.siteVar.referer;
            postData.emailUI = true;
            postData.emailId = "1";
            if (window.siteVar.referer === "IE") {
              postData.ieBooking = true;
            }

            axios
              .post(process.env.REACT_APP_MARKETING_API, postData)
              .then((response) => {
                let res = response.status;
                // res = 404; if want to test failed page state, uncomment this out.

                if (res === 200) {
                  setResponse("success");
                 
                  const queryString = new URLSearchParams(window.location.search) //then handle ga4
                  //console.log('queryString',queryString);
                  const urlParams = new URLSearchParams(queryString);
                  const cid = urlParams.get("cid")
                 
                  if (cookies.smid) {
                    //console.log('Sending smid specific results', cookies.smid);
                    ReactGA.event({
                      category: "smid-emailSignUp",
                      action: "submit success",
                      label: "smid-subscribeModal."+cookies.smid+"",
                    });
                  } else if (urlParams.has('cid')){
                    //console.log('Sending cid specific results');
          ReactGA.event({
          category: "emailSignUp",
          action: "submit success",
          label: "subscribeModal."+cid+"",
        });
      } else {
        //console.log('Sending');
        ReactGA.event({
          category: "emailSignUp",
          action: "submit success",
          label: "subscribeModal"
        });
      }
                } else {
                  setResponse("failure");
                }
                 //first send facebook data
                 fbq("trackCustom", "EmailSubscription", {
                  email: data.Email,
                });
              });
          })}
        >
          <div className="col col-10 col-lg-6 col-xl-5 mx-auto">
            <label className="text-light">Email address:</label>
            <br />
            <input
              placeholder="example@email.com"
              name="Email"
              id="emailInput"
              {...register("Email", {
                required: "Please enter your email address",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Please check your email address",
                },
              })}
              className="textarea w-100 text-center rounded-0 border-0 p-2"
            ></input>
            <p className="errorMsg">{errors.Email?.message}</p>
          </div>
          <div className="col col-10 col-lg-6 col-xl-5 mx-auto mt-3 mb-3">
            {response === "unassigned" && (
              <button
                className="btn btn-md btn-warning border-0 w-100 rounded-0 ps-5 pe-5 pt-2 pb-2"
                type="submit"
                style={{ backgroundColor: "rgb(255,164,9)" }}
              >
                Subscribe
              </button>
            )}
            {response === "success" && (
              <h3 className="text-success text-center fs-5">
                Your subscription was successful
              </h3>
            )}
            {response === "failure" && (
              <h3 className="text-danger text-center fs-5">
                Your subscription was unsuccessful
              </h3>
            )}
          </div>
        </form>
        <a className="text-center link-light mb-3 mt-3" href={link}>
          <small> Click here to read our full privacy policy.</small>
        </a>
      </div>
    </div>
  );
}

export default Intro;
